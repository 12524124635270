import * as yup from "yup"

export const PartOneSchema = yup.object().shape({
  name: yup.string().required("El nombre es requerido"),
  last_name: yup.string().required("Los apellidos son requeridos"),
  email: yup
    .string()
    .email("El email debe ser válido")
    .required("El email es requerido"),
  usage: yup.string().required("Por favor elija un uso"),
  other_use: yup.string().when("usage", {
    is: (usage: string) => usage === "Otro",
    then: yup
      .string()
      .min(5, '"Otro uso" debe ser de al menos 5 caracteres')
      .required('Por favor proporciona el "Otro uso" que le darás.')
      .nullable(true),
    otherwise: yup.string().nullable(true),
    //otherwise: yup.mixed().nullable(true),
  }),
  terms_and_conditions: yup.boolean().oneOf([true]).required(),
  privacy_policy: yup.boolean().oneOf([true]).required(),
})
