import { Button, Flex, Heading, Image, Text } from "@chakra-ui/react"
import React from "react"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import sent from "./../../assets/sent.png"
import { selectFormState, toggleModalOpen } from "./FormSlice"

export const FormSentInfo = () => {
  const dispatch = useAppDispatch()
  const { formPartOneData, errorMessage } = useAppSelector(selectFormState)
  const { name, email } = formPartOneData || {}

  const Success = (
    <>
      <Heading size="lg" mb={5} color="heading">
        Solicitud enviada
      </Heading>
      <Image src={sent} title="info-sent" mb={5} />
      <Text>¡Hola {name?.toLocaleUpperCase()}!</Text>
      <Text mb={4}>
        Estamos analizando la credencial que cargaste, en un momento recibiras
        el resultado en tu correo:
      </Text>
      <Text fontWeight="bold">{email?.toLowerCase()}</Text>
    </>
  )

  const Error = (
    <>
      <Heading size="lg" mb={5} color="heading">
        Error
      </Heading>
      <Heading size="sm" color="error">
        {errorMessage}
      </Heading>
    </>
  )

  return (
    <Flex
      fontSize="sm"
      direction="column"
      align="center"
      textAlign="center"
      maxW="525px"
      mx="auto"
    >
      {errorMessage ? Error : Success}
      <Button mt={[6, 10]} onClick={() => dispatch(toggleModalOpen())}>
        {errorMessage ? "Salir" : "Cerrar"}
      </Button>
    </Flex>
  )
}
