import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "../../app/store"
import { ApiService } from "../../services/apiService"

export interface FormPartOneData {
  name: string
  last_name: string
  email: string
  usage: string
  other_use?: string | null
  privacy_policy: boolean
  terms_and_conditions: boolean
}

export interface FormPartTwoData {
  frontFile?: File[]
  backFile?: File[]
}

export interface FormState {
  status: "idle" | "loading" | "failed"
  isFormModalOpen: boolean
  showFormPartTwo: boolean
  formPartOneData?: FormPartOneData
  formPartTwoData?: FormPartTwoData
  sentSuccess: boolean
  errorMessage?: string
}

const initialState: FormState = {
  status: "idle",
  isFormModalOpen: false,
  showFormPartTwo: false,
  formPartOneData: undefined,
  formPartTwoData: undefined,
  sentSuccess: false,
  errorMessage: undefined,
}

const apiService = new ApiService()

const getUse = (partOne: FormPartOneData) => {
  const { usage, other_use } = partOne
  return other_use ? other_use : usage
}

interface SendFormParams {
  token?: string
}

export const sendForm = createAsyncThunk(
  "form/sendForm",
  async ({ token }: SendFormParams, { getState, rejectWithValue }) => {
    const state = getState() as RootState
    const { formPartOneData, formPartTwoData } = state.formReducer

    try {
      if (formPartOneData && formPartTwoData) {
        const usage = getUse(formPartOneData)
        const { other_use, ...restFormPartOneData } = formPartOneData
        const status = (await apiService.sendFormData({
          ...restFormPartOneData,
          usage,
          ...formPartTwoData,
          token,
        })) as Partial<FormState>

        return { success: status }
      } else {
        throw new Error("No form data provided")
      }
    } catch (error) {
      console.error({ error })
      return rejectWithValue(error)
    }
  }
)

export const FormSlice = createSlice({
  name: "formReducer",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    toggleModalOpen: (state) => {
      if (!state.isFormModalOpen) {
        state.showFormPartTwo = false
        state.formPartTwoData = undefined
        state.formPartOneData = undefined
        state.errorMessage = undefined
        state.sentSuccess = false
      }
      state.isFormModalOpen = !state.isFormModalOpen
    },
    setFormPartOneData: (state, action: PayloadAction<FormPartOneData>) => {
      state.formPartOneData = action.payload
      state.showFormPartTwo = true
    },
    setFormFrontImage: (state, action) => {
      state.formPartTwoData = {
        ...state.formPartTwoData,
        frontFile: action.payload,
      }
    },
    setFormBackImage: (state, action) => {
      state.formPartTwoData = {
        ...state.formPartTwoData,
        backFile: action.payload,
      }
    },
    reset: (state) => {
      state = initialState
    },
  },
  extraReducers: {
    [sendForm.pending.type]: (state) => {
      state.errorMessage = undefined
      state.status = "loading"
    },
    [sendForm.rejected.type]: (state, action) => {
      const { name, message } = action.payload
      if (name === "AttepmtExceededError") {
        state.errorMessage = message
      } else {
        state.errorMessage = message
      }
      state.status = "failed"
    },
    [sendForm.fulfilled.type]: (state) => {
      state.sentSuccess = true
      state.status = "idle"
    },
  },
})

export const {
  toggleModalOpen,
  setFormPartOneData,
  setFormFrontImage,
  setFormBackImage,
} = FormSlice.actions

export const selectFormState = (state: RootState) => state.formReducer

export default FormSlice.reducer
