export class AttepmtExceededError extends Error {
  status?: number
  type: string

  constructor(message: string, type: string, status?: number) {
    super()
    this.name = this.constructor.name
    this.type = type
    this.message = message
    this.status = status || 422
  }
}

export class INEServerValidationError extends Error {
  code: string

  constructor(message: string, code: string) {
    super()
    this.name = this.constructor.name
    this.message = message
    this.code = code
  }
}
