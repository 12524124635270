import { Box, Button, Flex, Text } from "@chakra-ui/react"
import React, { useState } from "react"
import { FileRejection } from "react-dropzone"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { DropZone } from "../../components/DropZone/DropZone"
import { FileErrorMessages } from "../../components/DropZone/FileErrorMessages"
import {
  selectFormState,
  sendForm,
  setFormBackImage,
  setFormFrontImage,
} from "./FormSlice"

export const FormPartTwo = () => {
  const dispatch = useAppDispatch()
  const { formPartTwoData, status } = useAppSelector(selectFormState)
  const { frontFile, backFile } = formPartTwoData || {}

  const isButtonEnabled =
    frontFile && frontFile.length > 0 && backFile && backFile.length > 0

  const submit = () => {
    grecaptcha.ready(function () {
      grecaptcha
        .execute("6LccbSEcAAAAAOg1x8oXV4egLiINx67XO-WErLp1", {
          action: "submit",
        })
        .then(function (token) {
          // Add your logic to submit to your backend server here.
          dispatch(sendForm({ token }))
        })
    })
  }

  return (
    <Flex direction="column" align="center">
      <Text fontSize="sm" textAlign="center">
        Carga las imágenes de la credencial, deberán ser Frente y Reverso en
        archivos diferentes, formato JPG o PNG y máximo 2Mb de peso
      </Text>
      <FormDropZones />

      <Button
        disabled={!isButtonEnabled}
        isLoading={status === "loading"}
        onClick={submit}
      >
        Enviar
      </Button>
    </Flex>
  )
}

const FormDropZones = () => {
  const { formPartTwoData } = useAppSelector(selectFormState)
  const [fileRejections, setFileRejections] = useState<FileRejection[]>([])
  const dispatch = useAppDispatch()

  const setFrontFile = (data: any) => {
    dispatch(setFormFrontImage(data))
  }
  const setBackFile = (data: any) => {
    dispatch(setFormBackImage(data))
  }

  return (
    <>
      <FileErrorMessages fileRejections={fileRejections} onClose={() => setFileRejections([])}/>
      <Flex
        width="100%"
        direction={["column", "row"]}
        textAlign="center"
        justify="space-around"
      >
        <Box py={[2, 6]}>
          <Box fontSize="sm" fontWeight="bold" color="accent">
            Frente
          </Box>
          <DropZone
            height={["auto", "170px"]}
            boxSizing="border-box"
            handler={setFrontFile}
            name="front-file"
            preview={
              formPartTwoData?.frontFile
                ? formPartTwoData?.frontFile[0]
                : undefined
            }
            onFileRejections={setFileRejections}
          />
        </Box>
        <Box py={[2, 6]}>
          <Box fontSize="sm" fontWeight="bold" color="accent">
            Reverso
          </Box>
          <DropZone
            height={["auto", "170px"]}
            boxSizing="border-box"
            handler={setBackFile}
            name="back-file"
            preview={
              formPartTwoData?.backFile
                ? formPartTwoData?.backFile[0]
                : undefined
            }
            onFileRejections={setFileRejections}
          />
        </Box>
      </Flex>
    </>
  )
}
