import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Link,
  Select,
} from "@chakra-ui/react"
import { yupResolver } from "@hookform/resolvers/yup"
import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form"

import { useAppDispatch } from "../../app/hooks"
import {
  togglePolicyModalOpen,
  toggleTermsModalOpen,
} from "../policyAndTermsModal/PolicyAndTermsSlice"
import { PartOneSchema } from "./schemas"
import { FormChangeHandler } from "./types"
interface FormPartOneProps {
  onSubmit: (data: any) => void
}

export const FormPartOne: React.FC<FormPartOneProps> = ({
  onSubmit = () => {},
}) => {
  const {
    register,
    handleSubmit,
    formState: { isValid, errors },
    setValue,
    getValues,
    trigger,
  } = useForm({
    resolver: yupResolver(PartOneSchema),
    mode: "onBlur",
  })
  const dispatch = useAppDispatch()
  const [showOtherUse, toggleShowOtherUse] = useState(false)
  const [otherUseValue, setOtherUseValue] = useState<string | null>(null)

  const changeHandler: FormChangeHandler = (event: any) => {
    const data = event.target
    setValue(data.id, data.value)
    trigger(data.id)
  }

  const checkBoxChangeHandler = (e: any) => {
    const data = e.target
    setValue(data.id, !!data.checked)
    trigger(data.id)
  }

  const useCaseChangeHandler = (e: any) => {
    const data = e.target
    const value = data.value

    if (value === "Otro") {
      toggleShowOtherUse(true)
      setOtherUseValue("")
    } else {
      setValue("other_use", null)
      trigger("other_use")
      toggleShowOtherUse(false)
      setOtherUseValue(null)
    }

    setValue(data.id, value)
    trigger(data.id)
  }

  const otherUseChangeHandler = (event: any) => {
    const data = event.target
    setValue(data.id, data.value)
    trigger(data.id)
  }

  const onSubmitHandler = (data: any) => {
    onSubmit(data)
  }

  const handleLink = (type: "terms" | "policy") => {
    if (type === "policy") {
      dispatch(togglePolicyModalOpen())
    } else {
      dispatch(toggleTermsModalOpen())
    }
  }

  useEffect(
    () => {
      register("name")
      register("email")
      register("last_name")
      register("terms_and_conditions")
      register("usage")
      register("other_use")
      register("privacy_policy")
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  return (
    <Box maxWidth={["100%", "290px"]} mx="auto">
      <form onSubmit={handleSubmit(onSubmitHandler)}>
        <FormControl id="email" isInvalid={!!errors.email}>
          <FormLabel>Correo Electrónico</FormLabel>
          <Input
            type="email"
            onChange={changeHandler}
            placeholder="Ingresa tu correo electrónico"
          />
          <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
        </FormControl>
        <FormControl id="name" isInvalid={!!errors.name}>
          <FormLabel>Nombre(s)</FormLabel>
          <Input
            type="text"
            required={true}
            onChange={changeHandler}
            placeholder="Ingresa tu nombre"
          />
          <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
        </FormControl>
        <FormControl id="last_name" isInvalid={!!errors.last_name}>
          <FormLabel>Apellidos</FormLabel>
          <Input
            type="text"
            onChange={changeHandler}
            placeholder="Ingresa tus apellidos"
          />
          <FormErrorMessage>{errors.last_name?.message}</FormErrorMessage>
        </FormControl>
        <FormControl id="usage" isInvalid={!!errors.usage}>
          <FormLabel>¿Qué uso le darás al reporte?</FormLabel>
          <Select
            fontWeight="400"
            fontSize="sm"
            onChange={useCaseChangeHandler}
            placeholder="Selecciona tu respuesta"
          >
            <option value="Voy a contratar a alguien">
              Voy a contratar a alguien
            </option>
            <option value="Voy a vender algo">Voy a vender algo</option>
            <option value="Voy a comprar algo">Voy a comprar algo</option>
            <option value="Otro">Otro</option>
          </Select>
          <FormErrorMessage>{errors.usage?.message}</FormErrorMessage>
        </FormControl>
        <FormControl
          id="other_use"
          isInvalid={!!errors.other_use}
          display={showOtherUse ? "block" : "none"}
        >
          <FormLabel>Otro uso</FormLabel>
          <Input
            type="text"
            onChange={otherUseChangeHandler}
            placeholder="Escribe qué otro uso le darás."
            value={otherUseValue || undefined}
          />
          <FormErrorMessage>{errors.other_use?.message}</FormErrorMessage>
        </FormControl>
        <FormControl id="terms_and_conditions">
          <Checkbox
            fontSize="sm"
            id="terms_and_conditions"
            alignItems="center"
            mt={6}
            mx={3}
            onChange={checkBoxChangeHandler}
          >
            <Box as="span" fontSize="14px">
              Acepto los{" "}
              <Link
                href=""
                onClick={(e) => {
                  e.preventDefault()
                  handleLink("terms")
                }}
                rel="inner"
                textDecoration="underline"
              >
                términos y condiciones
              </Link>
            </Box>
          </Checkbox>
        </FormControl>
        <FormControl id="privacy_policy">
          <Checkbox
            fontSize="sm"
            id="privacy_policy"
            alignItems="center"
            mt={2}
            mx={3}
            onChange={checkBoxChangeHandler}
          >
            <Box as="span" fontSize="14px">
              Acepto la{" "}
              <Link
                href=""
                onClick={(e) => {
                  e.preventDefault()
                  handleLink("policy")
                }}
                rel="inner"
                textDecoration="underline"
              >
                política de privacidad
              </Link>
            </Box>
          </Checkbox>
        </FormControl>
        <FormControl id="next" display="flex" justifyContent="center">
          <Button disabled={!isValid} type="submit">
            Siguiente
          </Button>
        </FormControl>
      </form>
    </Box>
  )
}
