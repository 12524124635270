import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  CloseButton,
  Text,
} from "@chakra-ui/react"
import React from "react"
import { FileRejection } from "react-dropzone"

const FIlE_ERRORS: { [key: string]: string } = {
  "file-invalid-type": "El archivo no tiene una extensión válida.",
  "file-too-large": "El archivo excede los 2MB de tamaño.",
}

interface FileErrorMessagesProps {
  fileRejections: FileRejection[]
  onClose?: () => void
}

export const FileErrorMessages: React.FC<FileErrorMessagesProps> = ({
  fileRejections,
  onClose = () => {},
}) => {
  if (fileRejections.length === 0) {
    return null
  }
  return (
    <>
      {fileRejections.map((rejection) => (
        <Alert status="error" fontSize="sm">
          <AlertIcon />
          <AlertTitle mr={2} maxW="40%">
            Error "{rejection.file.name}"
          </AlertTitle>
          <AlertDescription>
            {rejection.errors.map((error) => {
              return <Text>{FIlE_ERRORS[error.code] || error.code}</Text>
            })}
          </AlertDescription>
          <CloseButton
            position="absolute"
            right="8px"
            top="8px"
            onClick={onClose}
          />
        </Alert>
      ))}
    </>
  )
}
