import { Button } from "./Button"
import { FormLabel } from "./FormLabel"
import { Input } from "./Input"
import { Modal } from "./Modal"
import { Select } from "./Select"
import { Checkbox } from "./Checkbox"

export const components = {
  Button,
  Checkbox,
  FormLabel,
  Input,
  Select,
  Modal,
}
