export const Terms = `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque eu eros at turpis eleifend gravida et sit amet ante. Integer volutpat sit amet sem vel hendrerit. Sed vitae congue lorem. Cras lacinia pretium consectetur. Morbi quis ligula id felis rutrum dictum. Suspendisse elementum tortor nisl, ac feugiat nibh venenatis in. Vestibulum convallis pharetra tellus, eget feugiat nibh malesuada nec. Vestibulum nisi nulla, cursus sit amet magna id, vestibulum posuere nisi. Donec tempus nunc ac eros efficitur luctus. Ut a odio turpis. Nam et imperdiet dolor, at lacinia purus. Donec porta mi et tempor feugiat. Vivamus vel felis purus. Maecenas at diam quis leo egestas tempor in at metus. Nunc mi nibh, pretium a vehicula nec, auctor sed justo.

Duis ultrices, ante et pulvinar posuere, sapien tortor suscipit ipsum, eget auctor eros ante nec odio. Nulla vulputate sed tellus sed porttitor. Morbi finibus sapien volutpat, rutrum tellus vitae, ultricies ante. Pellentesque dui tellus, condimentum sit amet purus a, tempus finibus eros. Phasellus ornare porttitor massa. Nullam sed elementum velit. Sed tristique nisl dui, in pulvinar eros aliquet vitae. Vivamus suscipit nibh vitae tincidunt viverra. Fusce eu blandit odio.

Maecenas pellentesque facilisis pellentesque. Curabitur sollicitudin ligula a mattis fermentum. Sed sed varius nulla, in pellentesque elit. In gravida sapien a sollicitudin maximus. Ut non ex sit amet eros viverra mattis. Praesent purus leo, molestie sit amet pellentesque id, maximus sit amet nisi. Aliquam euismod diam ante, ac efficitur dui rhoncus eu. Cras placerat turpis et odio maximus, nec elementum ipsum efficitur. Aliquam erat volutpat. Integer tincidunt ex eu odio placerat, eget tempor ipsum bibendum. Quisque semper orci a est porttitor placerat. Quisque tincidunt, quam eu laoreet ornare, nunc nisi porttitor nisi, nec mollis arcu sem sed justo. Curabitur id lectus lectus. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.

Curabitur cursus sem ut magna elementum tincidunt ut commodo augue. Etiam viverra molestie mi. Praesent vitae metus lorem. Nullam elementum semper purus vitae tristique. Praesent lobortis at elit sed egestas. Suspendisse nec fermentum orci. In nec pretium arcu. Quisque ligula justo, consequat sed ex ultricies, fermentum blandit libero. Quisque magna tortor, accumsan eget metus id, commodo auctor neque. Fusce est mauris, porta id risus ut, finibus imperdiet eros. Donec maximus, quam sit amet hendrerit maximus, nibh mi interdum magna, nec mattis magna augue vel orci. Nam suscipit varius quam, quis laoreet elit ullamcorper quis. Nullam luctus, est vel ultrices dapibus, eros massa finibus diam, et consequat mi dui a dolor. Proin elementum pulvinar sem, sit amet vehicula lorem auctor id. Nunc hendrerit viverra lacus non dapibus.

Cras ut auctor ex. Proin sed erat quis sapien mollis tristique ut nec tellus. Nunc pretium mollis scelerisque. Etiam sed sem ac felis vehicula eleifend. Pellentesque nunc ipsum, ultricies in sapien eget, sollicitudin viverra orci. Vivamus quis dolor efficitur, sagittis dui quis, sollicitudin ante. Suspendisse eu arcu malesuada, tristique massa nec, efficitur nisl. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae;

Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Fusce porttitor id turpis quis porttitor. Cras sed congue purus, id blandit lectus. Ut suscipit at lorem commodo ullamcorper. Pellentesque pharetra auctor nunc eget ullamcorper. Cras sit amet urna ut nulla rhoncus finibus. Duis semper nulla ac interdum auctor. Fusce pulvinar, nisl eget lacinia imperdiet, ex ex vehicula dolor, sed malesuada leo est eget libero. Suspendisse quis magna quis tortor finibus aliquam nec placerat erat. Aenean rhoncus pretium tellus, eget porta nibh posuere sit amet. Mauris non est vitae purus congue porta vitae vulputate elit.`

export const Policy = Terms
