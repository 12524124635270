import {
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react"
import React from "react"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { FormPartOne } from "./FormPartOne"
import { FormPartTwo } from "./FormPartTwo"
import { FormSentInfo } from "./FormSentInfo"
import {
  selectFormState,
  setFormPartOneData,
  toggleModalOpen,
} from "./FormSlice"

export const FormModal = () => {
  const dispatch = useAppDispatch()
  const {
    isFormModalOpen,
    showFormPartTwo,
    sentSuccess,
    status,
    errorMessage,
  } = useAppSelector(selectFormState)

  const isLoading = status === "loading"
  const onToggleFunc = () => {
    dispatch(toggleModalOpen())
  }

  const { isOpen, onClose } = useDisclosure({
    isOpen: isFormModalOpen,
    onOpen: onToggleFunc,
    onClose: onToggleFunc,
  })

  const onPartOneSubmit = (data: any) => {
    dispatch(setFormPartOneData({ ...data }))
  }

  const label = showFormPartTwo
    ? "Paso 2 de 2: credencial"
    : "Paso 1 de 2: tu información"

  const Form = (
    <>
      <Heading textAlign="center" size="lg" mb={5} color="heading">
        Validación de credencial INE
      </Heading>
      <Heading textAlign="center" size="sm" mb={10} color="heading">
        {label}
      </Heading>
      {!showFormPartTwo && <FormPartOne onSubmit={onPartOneSubmit} />}
      {showFormPartTwo && <FormPartTwo />}
    </>
  )

  return (
    <Modal
      closeOnOverlayClick={!isLoading}
      closeOnEsc={!isLoading}
      isOpen={isOpen}
      onClose={onClose}
      scrollBehavior="inside"
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton disabled={isLoading} />
        <ModalBody>
          {sentSuccess || errorMessage ? <FormSentInfo /> : Form}
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
