import axios from "axios"
import {
  FormPartOneData as DirtyFormPartOneData,
  FormPartTwoData,
} from "../features/formModal/FormSlice"
import { AttepmtExceededError, INEServerValidationError } from "./errors"

type FormPartOneData = Omit<DirtyFormPartOneData, "other_use">

interface VerifyFormData extends FormPartOneData, FormPartTwoData {
  token?: string
}

export class ApiService {
  private serverUrl: string
  constructor() {
    if (process.env.REACT_APP_SERVER_URL) {
      this.serverUrl = process.env.REACT_APP_SERVER_URL
    } else {
      throw new Error("No server url provided")
    }
  }

  public sendFormData = async (form: VerifyFormData): Promise<boolean> => {
    const url = this.serverUrl + "/verify"
    try {
      const formData = this.buildFormData(form)
      const response = await axios.post(url, formData)

      const { status } = response.data
      return status === "OK"
    } catch (error) {
      throw this.handleErrorResponse(error)
    }
  }

  private buildFormData = (data: VerifyFormData) => {
    const { frontFile, backFile, ...otherData } = data

    const newData = {
      ...otherData,
      FrontFile: frontFile ? frontFile[0] : undefined,
      BackFile: backFile ? backFile[0] : undefined,
    }

    const formData = new FormData()

    Object.entries(newData).forEach(([key, value]) => {
      if (value !== undefined) {
        const sanitizedValue =
          typeof value === "boolean" ? value.toString() : value

        formData.append(key, sanitizedValue)
      }
    })

    return formData
  }

  private handleErrorResponse = (error: any) => {

    const errorResponse = error.response.data

    if (errorResponse.type === "EXCEEDED_ATTEMPTS") {
      return new AttepmtExceededError(
        errorResponse.message,
        errorResponse.type,
        errorResponse.status
      )
    } else if (errorResponse.code) {
      return new INEServerValidationError(
        errorResponse.message,
        errorResponse.code
      )
    }
    return new Error(error)
  }
}
