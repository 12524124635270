const baseStyle = {
  field: {
    width: '100%',
    // outline: 0,
    border: '1px solid',
    borderColor: 'gray',
    borderBottom: '1px solid',
    borderBottomColor: 'primary',
    borderRadius: '4px',
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    position: 'relative',
    appearance: 'none',
    transition: 'all 0.2s',
    pt: '2px',
    _focus: {
      // borderColor: 'secondary',
      // boxShadow: 'none',
    },
  },
  label: {
    color: 'secondary',
  }
}

const variants = {
  outline: {
    field: {
      border: '1px solid',
      borderColor: 'gray',
      borderBottom: '1px solid',
      borderBottomColor: 'primary',
      color: 'primary',
      
      webkitAppearance: 'none',
      _focus: {
        // borderColor: 'secondary',
        // boxShadow: 'none',
      },
      _hover: {},
      _invalid: {
        bg: 'transparent',
        borderColor: 'input.error',
      },
      _disabled: {
        bg: 'disabled',
        border: 'disabled',
        color: 'offwhite',
      },
    },
  },
}

const defaultProps = {
  size: 'md',
  variant: 'outline',
}

export const Input = {
  parts: {
    field: '',
    addon: '',
  },
  defaultProps,
  baseStyle,
  variants,
}
