import { Box, Button, Flex, Heading, Image, Text } from "@chakra-ui/react"
import React from "react"
import { useAppDispatch } from "../../app/hooks"
import { toggleModalOpen } from "../formModal/FormSlice"
import mobile from "./../../assets/mobile.png"


export const LandingText = () => {
  const dispatch = useAppDispatch()

  return (
    <Flex
      maxW="1260px"
      flexDirection={["column-reverse", null, "row"]}
      mt={[-7]}
      justify={"center"}
    >
      <Flex flex="50%" p={[10, 20]} pl={[10, "10%"]} align="center" flexDirection="column" textAlign="left">
        <Heading mb="8" color="heading">Validación gratuita de tu credencial INE</Heading>
        <Text mb="5" fontSize="md">
          ¿Necesitas validar la autenticidad de una credencial INE? Es muy
          fácil, rápido, y seguro y ¡Gratis!
        </Text>
        <Text mb="5" fontSize="md">
          Sólo ingresa tu correo electrónico, y las imágenes de frente y vuelta
          de la credencial, al recibirlas, harémos el proceso de validación y en
          pocos minutos enviarémos el resultado a tu correo.
        </Text>
        <Flex justify="center">
          <Button
            onClick={() => {
              dispatch(toggleModalOpen())
            }}
          >
            Comienza aquí
          </Button>
        </Flex>
      </Flex>
      <Box>
        <Image
          src={mobile}
          title="mobile-device"
          maxH={["300px", null, "unset"]}
        />
      </Box>
    </Flex>
  )
}
