import { ChakraProvider, Flex } from "@chakra-ui/react"
import React from "react"
import "./App.css"
import theme from "./design/theme"
import { FormModal } from "./features/formModal/FormModal"
import { LandingImage } from "./features/landing/LandingImage"
import { LandingText } from "./features/landing/LandingText"
import { PolicyAndTermsModal } from "./features/policyAndTermsModal/PolicyAndTermsModal"

function App() {

  return (
    <ChakraProvider theme={theme}>
      <Flex className="App" align="center" direction="column">
        <LandingImage />
        <LandingText />
      </Flex>
      <FormModal />
      <PolicyAndTermsModal />
    </ChakraProvider>
  )
}

export default App
