const defaultProps = {
  variant: 'primary',
  size: 'md',
}

export const Button = {
  defaultProps,
  baseStyle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: 'none',
    bg: 'buttons.primary',
    lineHeight: 'tight',
    fontWeight: 'bold',
    fontFamily: 'body',
    my: 4,
    px: 4,
    width: '260px',
    _focus: {
      boxShadow: 'none',
    },
  },

  variants: {
    primary: {
      borderRadius: '25px',
      fontWeight: '800',
      fontSize: 'lg',
      bg: 'buttons.primary',
      px: 5,
      py: 2,
      height: "50px",
      width: '260px',
      color: 'white',
      _hover: {
        bg: 'hover',
        _disabled: {
          bg: 'disabled',
        }
      },
      _disabled: {
        border: 'none',
        color: 'white',
        bg: 'disabled',
        cursor: 'default',
        opacity: 0.6,
      },
    },
  },
}
