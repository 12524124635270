// theme.js
import { components } from "./components"
import { extendTheme } from "@chakra-ui/react"

const config = {
  useSystemColorMode: false,
}

const globalStyles = {
  global: {
    "html, body, #root": {
      width:"100%",
      height: "100%",
    }
  },
}

const space = {
  px: "1px",
  "0": "0rem",
  "1": "0.25rem",
  "2": "0.5rem",
  "3": "0.75rem",
  "4": "1rem",
  "5": "1.25rem",
  "6": "1.5rem",
  "8": "2rem",
  "10": "2.5rem",
  "12": "3rem",
  "16": "4rem",
  "20": "5rem",
  "24": "6rem",
  "32": "8rem",
  "40": "10rem",
  "48": "12rem",
  "56": "14rem",
  "64": "16rem",
}

const themeConfig = {
  styles: globalStyles,
  fonts: {
    heading: "Montserrat",
    body: "Montserrat",
  },
  fontSizes: {
    xs: "0.63rem",
    sm: "0.75rem",
    md: "1rem",
    lg: "1.125rem",
    xl: "1.25rem",
    "2xl": "1.5rem",
    "3xl": "1.875rem",
    "4xl": "2.25rem",
    "5xl": "3rem",
    "6xl": "3.75rem",
    "7xl": "4.5rem",
    "8xl": "6rem",
    "9xl": "8rem",
  },
  colors: {
    transparent: "transparent",
    black: "#000",
    white: "#FFF",
    error: "#FF0000",
    modal: {
      bg: "#0A2F877D",
      upload: "#3D3D3D",
      file: "#E9E9E9",
    },
    accent: "#2BACD2",
    heading: "#0A2F87",
    primary: "#0C3288",
    secondary: "#2FADD3",
    gray: "#D7DAE0",
    buttons: {
      primary: "#14A9D2",
    },
    disabled: "#BCBEBF",
    // ...
  },
  space,
  components,
}

// 3. extend the theme
const theme = extendTheme({ config, ...themeConfig })

export default theme
