import { createSlice } from "@reduxjs/toolkit"
import { RootState } from "../../app/store"

export interface PolicyAndTermsState {
  isPolicyModalOpen: boolean
  isTermsModalOpen: boolean
}

const initialState: PolicyAndTermsState = {
  isPolicyModalOpen: false,
  isTermsModalOpen: false,
}

export const PolicyAndTermsSlice = createSlice({
  name: "policyAndTermsReducer",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    togglePolicyModalOpen: (state) => {
      state.isPolicyModalOpen = !state.isPolicyModalOpen
    },
    toggleTermsModalOpen: (state) => {
      state.isTermsModalOpen = !state.isTermsModalOpen
    },
  },
})

export const {
  togglePolicyModalOpen,
  toggleTermsModalOpen,
} = PolicyAndTermsSlice.actions

export const selectPolicyAndTermsReducerState = (state: RootState) => state.policyAndTermsReducer

export default PolicyAndTermsSlice.reducer
