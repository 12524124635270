const baseStyle = {
  overlay: {
    bg: "modal.bg",
    width: "100%",
    height: "100%",
  },
  dialogContainer: {
    width: "100%",
    margin: ["auto", "auto"],
    maxHeight: "100%",
    bottom: "0",
    overflow: "auto",
  },
  dialog: {
    borderRadius: "16px",
    m: ["0px", "auto", "60px"],
    width: ["90%", "100%", "800px"],
    maxWidth: ["90%", "90%", "800px"],
    maxHeight: "calc(100% - 50px)",
    p: ["30px 10px 10px", "60px 60px 20px"],
  },
  body: {
    overflow: "auto",
  },
}

const defaultProps = {
  // size: "xs",
}

export const Modal = {
  parts: {
    overlay: "",
  },
  defaultProps,
  baseStyle,
  // sizes,
  // variants,
}
