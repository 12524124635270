import { Box } from "@chakra-ui/react"
import React from "react"
import landing from "./../../assets/landing.png"

export const LandingImage = () => {
  return (
    <Box
      backgroundImage={landing}
      backgroundRepeat="no-repeat"
      bgPosition="bottom center"
      bgSize="cover"
      title="landing-heading"
      w="100%"
      minHeight={["150px", "200px"]}
      maxW={["initial", null]}
    />
  )
}
