import { Box, Flex, FlexProps, Image, Text } from "@chakra-ui/react"
import React, { useEffect } from "react"
import { FileRejection, useDropzone } from "react-dropzone"
import { useAppSelector } from "../../app/hooks"
import { UploadIcon } from "../../components/Icons/UploadIcon"
import { selectFormState } from "../../features/formModal/FormSlice"

interface DropZoneProps extends FlexProps {
  name: string
  handler: (data: any) => void
  preview?: File
  onFileRejections?: (fileRejections: FileRejection[]) => void
}

export const DropZone: React.FC<DropZoneProps> = ({
  handler,
  name,
  preview,
  onFileRejections = () => {},
  ...flexProps
}) => {
  const { status } = useAppSelector(selectFormState)
  const isLoading = status === "loading"
  const { acceptedFiles, fileRejections, getRootProps, getInputProps } =
    useDropzone({
      disabled: isLoading,
      maxSize: 2000000,
      accept: ["image/png", "image/jpeg", "image/jpg"],
    })

  useEffect(
    () => {
      handler(acceptedFiles)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [acceptedFiles]
  )

  useEffect(
    () => {
      if (fileRejections.length > 0) {
        onFileRejections(fileRejections)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fileRejections]
  )

  return (
    <Flex
      direction="column"
      justify="center"
      align="center"
      {...getRootProps({ className: "dropzone" })}
      p={[5, 8]}
      color="modal.upload"
      border="1px dashed"
      bordercolor="modal.upload"
      background="modal.file"
      {...flexProps}
    >
      <input {...getInputProps()} type="file" name={name} />
      {!preview && (
        <>
          <Box
            padding="3px 10px"
            borderRadius="4px"
            border="1px solid"
            borderColor="modal.upload"
            fontWeight="600"
            fontSize="sm"
            lineHeight="30px"
            mb={4}
          >
            <UploadIcon height="18px" width="18px" mr={1} /> Cargar archivo
          </Box>
          <Text fontSize="sm">O arrastra tu archivo aquí</Text>
        </>
      )}
      {preview && (
        <>
          <Box fontSize="sm">{preview.name}</Box>
          <Image src={createPreview(preview)} maxH={["70px", "120px"]} />
        </>
      )}
    </Flex>
  )
}

const createPreview = (preview: File) => {
  return URL.createObjectURL(preview)
}
