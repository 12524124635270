import {
  Box,
  Button,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react"
import React from "react"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { Policy, Terms } from "./constants"
import {
  selectPolicyAndTermsReducerState,
  togglePolicyModalOpen,
  toggleTermsModalOpen,
} from "./PolicyAndTermsSlice"

export const PolicyAndTermsModal = () => {
  const dispatch = useAppDispatch()
  const { isPolicyModalOpen, isTermsModalOpen } = useAppSelector(
    selectPolicyAndTermsReducerState
  )

  const onToggleFunc = () => {
    if (isPolicyModalOpen) {
      dispatch(togglePolicyModalOpen())
    } else {
      dispatch(toggleTermsModalOpen())
    }
  }

  const { isOpen, onClose } = useDisclosure({
    isOpen: isPolicyModalOpen || isTermsModalOpen,
    // onOpen: onToggleFunc,
    onClose: onToggleFunc,
  })

  const Info = (
    <Flex direction="column" align="center">
      <Box overflow="auto" maxHeight="371px">
        <Heading textAlign="center" fontSize={["19px", "24px"]} mb={5} color="heading">
          {isPolicyModalOpen
            ? "Politica de Privacidad"
            : "Términos y condiciones"}
        </Heading>
        <Text fontSize={["sm", "14px"]} whiteSpace="pre-wrap">
          {isPolicyModalOpen ? Policy : Terms}
        </Text>
      </Box>
      <Button mt={[6, 10]} onClick={onToggleFunc}>
        Regresar
      </Button>
    </Flex>
  )

  return (
    <Modal isOpen={isOpen} onClose={onClose} scrollBehavior="inside" isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody>{Info}</ModalBody>
      </ModalContent>
    </Modal>
  )
}
